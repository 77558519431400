import React, { useContext, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';
import { Message } from '@components';
import Text from '@dt/ahura/src/components/Text';
import { get_status } from '@dt/graphql-support/horizon/ts/configurations_shadow';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.blue[50],
        borderRadius: 4,
        color: theme.palette.blue[10],
        padding: 4,
    },
}));
const ApiShadowAssetsPageChartLoadingContainer = () => {
    const css = useStyles();
    const { bulkId, setBulkId, setIsPolling, setLatestRefetchDate } = useContext(ShadowAssetsContext);
    const { data, loading, error } = useQuery(get_status, {
        notifyOnNetworkStatusChange: true,
        pollInterval: 5000,
        skip: !bulkId,
        variables: {
            id: bulkId,
        },
    });
    useEffect(() => {
        if (loading) {
            setIsPolling(loading);
        }
    }, [loading, setIsPolling]);
    useEffect(() => {
        const results = data?.shadow_assets_check_status;
        const isCompleted = results?.status === 'COMPLETED';
        if (isCompleted) {
            setIsPolling(false);
            setBulkId('');
            setLatestRefetchDate(new Date());
        }
    }, [data, setBulkId, setLatestRefetchDate, setIsPolling]);
    if (loading) {
        return React.createElement(Skeleton, { height: 50, width: '100%' });
    }
    if (error) {
        return React.createElement(Message, { m: 0, message: error.message, variant: 'error' });
    }
    if (!bulkId) {
        return null;
    }
    const results = data?.shadow_assets_check_status;
    return (React.createElement(Grid, { item: true, xs: 12 },
        React.createElement("div", { className: css.container, style: {
                alignItems: 'center',
                display: 'flex',
                marginBottom: 8,
            } },
            React.createElement(CircularProgress, { size: 16, style: { marginRight: 8 } }),
            React.createElement(Text, { component: "div", variant: "body" }, "Scan in progress")),
        React.createElement("div", { className: css.container },
            React.createElement(Text, { component: "div", variant: "body" },
                results?.completed_tasks_count,
                " out of ",
                results?.total_tasks_count,
                " completed..."))));
};
export default ApiShadowAssetsPageChartLoadingContainer;
